import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

export const BannerEaster: React.FC = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.BannerMobile alt="Banner Páscoa" />
      <S.BannerDesktop alt="Banner de Páscoa" />

      <S.AreaCta>
        <S.GroupText>
          <p>
            A Orthopride é a rede de clínicas de ortodontia que mais cresce no
            Brasil! Marque já a sua avaliação e descubra o que podemos fazer por
            você!
          </p>
        </S.GroupText>
        <S.GroupButtons>
          <S.Button
            btStyle="secondary"
            type="button"
            onClick={() => history.push("/encontre-uma-unidade")}
          >
            Encontre uma unidade
          </S.Button>
          <S.Button
            btStyle="light"
            type="button"
            onClick={() => history.push("/agendar-avaliacao")}
          >
            Agende online
          </S.Button>
        </S.GroupButtons>
      </S.AreaCta>
    </S.Container>
  );
};
