import styled from "styled-components";
import imgDesktop from "assets/images/banner-pascoa/desktop.jpg";
import imgMobile from "assets/images/banner-pascoa/mobile.jpg";
import { colors, colorScheme, fonts } from "styles";
export { Button } from "styles/styled-components";

export const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: auto;
  /* background url(${imgDesktop}) no-repeat top center; */
  /* background-size: cover; */
`;

export const BannerMobile = styled.img.attrs({ src: imgMobile })`
  display: none;
  @media screen and (max-width: 1220px) {
    display: block;
    width: 100%;
    height: auto;
  }
`;
export const BannerDesktop = styled.img.attrs({ src: imgDesktop })`
  display: block;
  width: 100%;
  height: auto;
  z-index: 0;
  @media screen and (max-width: 1220px) {
    display: none;
  }
`;

export const AreaCta = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 550px;
  height: auto;
  padding: 50px;
  z-index: 20;
  margin-right: 220px;

  @media screen and (max-width: 1850px) {
    margin-right: 180px;
  }
  @media screen and (max-width: 1700px) {
    margin-right: 145px;
  }
  @media screen and (max-width: 1600px) {
    margin-right: 100px;
  }
  @media screen and (max-width: 1440px) {
    margin-right: 90px;
    padding: 25;
  }
  @media screen and (max-width: 1382px) {
    padding: 0;
    padding-bottom: 80px;
  }
  @media screen and (max-width: 1345px) {
    padding: 0;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 1220px) {
    display: none;
  }
`;
export const GroupText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  @media screen and (max-width: 1382px) {
    margin-bottom: 20px;
  }
  p {
    font-family: ${fonts.SourceSansProRegular};
    font-size: 18px;
    color: #000;
    line-height: 1.4em;
  }
`;
export const GroupButtons = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
`;
