import styled from "styled-components";

interface ContainerProps {
  active: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: none;
  ${(props) => props.active && "display: flex;"}
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: 100;
`;
export const CloseWarning = styled.button`
  font-size: small;
  padding: 8px 16px;
  margin-top: 16px;
  color: #fff;
  background: red;
  border-radius: 4px;
  cursor: pointer;
`;

export const Text = styled.p`
  width: 80%;
  max-width: 1100px;
  margin: 0 auto;
  color: #fff;
  font-size: small;
  text-align: left;
  a {
    color: #fff;
    text-decoration: underline;
  }
`;
